.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: radial-gradient(circle, #f3f3f3, #e0e0e0);
    text-align: center;
    color: #333;
}

.not-found-content {
    max-width: 600px;
    padding: 40px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    animation: fadeIn 1s ease-in-out;
    position: relative;
    overflow: hidden;
}

.not-found-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://source.unsplash.com/random/1600x900') no-repeat center center;
    background-size: cover;
    opacity: 0.1;
    z-index: 0;
}

.error-code {
    font-size: 120px;
    font-weight: bold;
    margin: 0;
    color: #ff6f61;
    z-index: 1;
}

.error-message {
    font-size: 24px;
    margin: 20px 0;
    color: #555;
    z-index: 1;
}

.home-link .back-button {
    background: linear-gradient(45deg, #6a11cb, #2575fc);
    border: none;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
    position: relative;
    z-index: 1;
}

.home-link .back-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
    background: linear-gradient(45deg, #2575fc, #6a11cb);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
