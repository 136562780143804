* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth !important;
}

body {
  scroll-behavior: smooth !important;
}

.custom-navbar-toggle {
  border: none !important;
  outline: none !important;
  box-shadow: 0px !important;
  font-size: 1.5rem !important;
}

.custom-navbar-toggle:hover {
  color: #ffffff;
  border: none !important;
  box-shadow: 0px !important;
  outline: none !important;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.2rem white !important;
}

.bg2 {
  width: 100%;
  box-shadow: 0px 5px 10px 0px rgba(9, 5, 29, 0.171) !important;
  background-color: white;
  position: sticky !important;
  top: 0px !important;
  z-index: 1 !important;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: gray !important;
  outline: 0;
  box-shadow: 0 0 0 rgba(13, 110, 253, .25) !important;
}

.navlink {
  color: #6eb4ff !important;
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  margin-left: 10px !important;
}

.navlink::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  color: #268bf7 !important;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.navlink:hover {
  width: 100% !important;
}

.navlink:active {
  width: 100%;
  color: #007bff !important;
}

.navlink.active::after,
.navlink:hover::after {
  width: 100%;
  color: #007bff !important;
}

.navlink.active {
  color: #007bff !important;
}

.categoryylink {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover !important;
  margin-top: 10px !important;
  margin: 8px !important;
  border-radius: 50% !important;
}

.productcard001 {
  border-radius: 10px !important;
}

.categoryy-container {
  text-align: center;
  white-space: nowrap !important;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.LiveFromSuratImg {
  width: 20% !important;
  margin-top: 5px;
}

.productimg {
  width: 25%;
  cursor: pointe r;
}

.bgproduct {
  background-color: #f3f3f3;
}

.post-item__inner .entry-meta {
  display: flex;
  flex-wrap: wrap;
}

.linkblog {
  font-weight: 700;
}

.linkblog {
  outline: none;
  text-decoration: none;
}

.linkblog {
  outline: none;
}

.linkblog {
  color: inherit;
}

.blogtxt {
  outline: none;
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 24px;
  transition-duration: 0.4s;
}

.blogtxt:hover {
  color: red;
}

.cardimg1 {
  object-fit: cover;
  border-radius: 35px !important;
  height: auto;
  width: 100%;
}

.cardmain {
  justify-content: center;
}

.appimg {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.entry-meta {
  display: flex;
  flex-wrap: wrap;
}

.productmain {
  flex-wrap: wrap !important;
}

.widget-mobile-apps {
  background-color: #e4eaee;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 10px;
  padding: 250px 0px 0px;
  display: flex;
  align-items: end;
}

.bg-footer {
  padding: 50px 0 30px;
}

.footer-link li {
  line-height: 25px;
  font-size: 14px !important;
  text-decoration: none !important;
}

.footerlink {
  text-decoration: none !important;
  color: #212529;
}

.footerend {
  background-color: #f3f3f3;
}

.footer-heading {
  font-size: 17px;
  font-weight: 700 !important;
  color: #012F76;
}

.footer-social-icon {
  color: black;
  font-size: x-large;
}

.list-inline-item:hover .footer-social-icon {
  color: white;
}

.dropdown-toggle {
  background-color: white !important;
  color: black !important;
  border: none !important;
}

li {
  list-style: none;
}

.links_name {
  font-weight: 700 !important;
}

.linksnameborder {
  border-bottom: 1px solid rgb(208, 208, 208) !important;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: rgb(0, 73, 137) !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  font-weight: 700 !important;
  padding: 4px 0px 0px 0px !important;
}

.accordion-body {
  padding: 0px 0px 0px 20px !important;
}

.categoriesbg {
  border-radius: 10px;
  align-items: center !important;
}

.right_conatct_social_icon {
  background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
}

.right_conatct_social_icon {
  height: 100%;
}

.info_single {
  margin: 30px 0px;
}

.info_single i {
  margin-right: 15px;
}

.info_single span {
  font-size: 14px;
  letter-spacing: 1px;
}

.map_sec {
  padding: 50px 0px;
}

.map_inner h4,
.map_inner p {
  color: #000;
  text-align: center;
}

.map_inner p {
  font-size: 13px;
}

.map_bind {
  margin-top: 50px;
  border-radius: 30px;
  overflow: hidden;
}

.error {
  color: red;
}

.product-cart button {
  border: 0;
  border-radius: 0 0 10px 10px;
  color: white;
  background-color: #f04141;
  padding: 1em;
  text-transform: uppercase;
  width: 100%;
  padding: 12px;
  font-weight: 500;
}

.product-cart button:hover {
  background-color: #e11616;
}

.product-cart-button {
  border: 0;
  color: white;
  background-color: #f04141;
  text-transform: uppercase;
  width: 100%;
  padding: 7px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}

.product-cart-button:hover {
  background-color: #e11616;
}

.slick-dots {
  position: absolute;
  bottom: -45px !important;
}

.policy {
  background-color: white !important;
  margin: auto !important;
}

.policy01 {
  border-right: 1px solid rgb(169, 169, 169);
}

.policy011 {
  border-right: 1px solid rgb(169, 169, 169);
}

.watchtxt {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.menu-nav {
  border-radius: 3px;
}

.menu-nav a {
  border-bottom: 1px solid rgb(161, 161, 161);
}

.bloghide {
  display: none;
}

.storehide {
  display: none;
}

.dropdownmain {
  background-color: rgb(239, 239, 239) !important;
  box-shadow: 0 0 5px 2px rgba(36, 36, 36, 0.16);
}

.dropdownmanu {
  background-color: rgb(239, 239, 239) !important;
}

nav ul {
  padding: 0 20px;
}

nav ul li {
  list-style-type: none;
  width: 200px;
}

nav ul a {
  display: flex;
  color: rgb(74, 74, 74);
  text-decoration: none;
  transition: 300ms all;
  padding: 10px;
  justify-content: space-between;
  font-weight: 500;
}

.menu-nav ul {
  margin-bottom: 0px;
}

nav ul a:hover {
  background: rgba(179, 179, 179, 0.83);
}

nav ul a span {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardtag {
  display: flex;
  flex-wrap: wrap;
}

.cardtag a {
  text-decoration: none;
}

.cardtag a p {
  margin-left: 5px;
  margin-right: 5px;
  color: rgb(74, 74, 74);
  background-color: white;
  font-weight: 500;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}

.cardtag a :hover {
  background-color: rgb(234, 234, 234);
}

.list-inline-item {
  background-color: rgb(236, 236, 236);
  padding: 2px 11px 4px 11px;
  border-radius: 7px;
}

.list-inline-item:hover {
  background-color: rgb(255, 67, 67);
  transition: all 0.3s ease-out 0s;
}

.form-check {
  margin-bottom: 0px !important;
}

.accordion-button {
  padding-top: 0px !important;
}

/* Shopproduct1.css */
.sidebar-heading {
  color: #012F76;
}

.sidebar-custom {
  width: 300px !important;
  top: 0 !important;
}

.sidebar {
  position: sticky !important;
  top: 90px !important;
  overflow-y: auto !important;
  display: block;
  max-height: 80vh;
  scrollbar-width: thin;
}

.header-filter {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 6px !important;
  z-index: 100 !important;
  padding: 10px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
}

.header-filter .search-group {
  justify-content: end !important;
  display: flex !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: var(--bs-btn-active-border-color);
}


.category-links .btn-link {
  color: #000000 !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  text-decoration: none !important;
}

.category-links .btn-link:hover {
  color: #368de9 !important;
}

.search-bar {
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-bar:focus {
  border-color: #e9f3ff !important;
  box-shadow: none !important;
}

.navbar-light .navbar-brand,
.navbar-light .nav-link {
  color: #333;
  font-weight: bold;
}

.form-control {
  border-radius: 30px;
}

.btn-outline-secondary,
.btn-outline-danger {
  border-radius: 30px;
}

.btn-outline-secondary:hover,
.btn-outline-danger:hover {
  background-color: #007bff;
  color: white;
}

.cardproductname {
  font-size: 16px !important;
}

.productcardimg1 {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.categories_name {
  width: auto;
  white-space: wrap;
}

.Categories_Image {
  height: 100px;
}

.link-button-container {
  background-color: #fcfdff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.btn-close:focus {
  box-shadow: none !important;
}

.icon-box {
  background-color: #eaf3ff;
  border-radius: 50%;
}

.link-title {
  color: #012F76;
}

.share_button_footer {
  color: #007bff;
}

.social_links {
  padding: 0;
  margin: 0;
}

.social-item {
  margin-bottom: 1rem;
}

.social-icon {
  border: 2px solid #e9f3ff;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #ffffff;
}

.social-icon-image {
  color: #012F76;
}

.social-text {
  color: #012F76;
}

.social_link_footer {
  text-decoration: none;
}

.category_Products {
  color: #012F76;
}

.filter_sidebar {
  width: 280px !important;
}

@media (min-width: 992px) {
  .sidebar-custom {
    width: 300px !important;
  }
}

@media screen and (max-width: 370px) {
  .sidebar-custom {
    width: 250px !important;
  }
}

@media screen and (max-width: 992px) {
  .categoryy-container {
    margin-left: 0px !important;
  }

  .widget-mobile-apps {
    padding: 0px 0px 0px;
  }

  .cardmain {
    margin-top: 20px !important;
  }

  .navbar-collapse {
    text-align: center !important;
  }

  .navbarmain {
    margin: 0px !important;
  }

  .storehide {
    display: contents;
  }

  .iteckstorehide {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .productcard001 {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .cardmain {
    display: flex !important;
    flex-wrap: wrap !important;
  }


  .blogcardmain {
    margin-bottom: 40px;
  }

  .policy011 {
    border: none;
  }

  .blogmain {
    display: content;
  }

  .menunavmain {
    display: none;
  }

  .cardtagmain {
    display: contents;
  }

  .dropdown1 {
    display: contents;
  }

  .bloghide {
    display: contents;
  }

  .blogcardmain1 {
    display: none;
    margin-bottom: 40px;
  }

  .header-filter {
    flex-direction: column !important;
  }

  .header-filter .category-links {
    margin-bottom: 10px !important;
  }

  .sidebar {
    display: none !important;
  }
}

@media screen and (max-width: 720px) {
  .LiveFromSuratImg {
    width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .policy01 {
    border: none;
  }

  .blogcardmain01 {
    display: flex;
    flex-wrap: wrap;
  }

  .slider_img_home {
    height: 300px !important;
  }
}

@media screen and (max-width: 480px) {
  .productimgmain {
    display: flex;
    flex-wrap: wrap !important;
  }

  .card001 {
    width: 100% !important;
  }

  .cardproductname {
    font-size: 14px !important;
  }
}