body {
  scroll-behavior: smooth !important;
}

/* Dashboard.css */
.profile_icn {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.list-group-item {
  font-size: 1rem !important;
  font-weight: bold !important;
}

.list-group-item.active {
  background-color: rgb(218, 218, 218) !important;
  color: #000000 !important;
}

.list-group-item.active:hover {
  background-color: rgb(167, 167, 167) !important;
}

#sidebar-wrapper {
  position: sticky !important;
  top: 0px !important;
  overflow-y: auto !important;
  height: 100vh;
  overflow-y: auto;
}

#page-content-wrapper {
  flex: 1;
  padding: 0;
}

.container-fluid {
  padding: 0;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: -240px !important;
}

#page-content-wrapper {
  width: 100% !important;
  transition: margin-left 0.3s ease !important;
}

#wrapper.toggled #page-content-wrapper {
  margin-left: 0 !important;
}

#wrapper {
  display: flex !important;
  width: 100% !important;
  align-items: stretch !important;
}

#sidebar-wrapper {
  min-width: 240px !important;
  max-width: 240px !important;
  transition: all 0.3s !important;
}

#page-content-wrapper {
  width: 100% !important;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: -240px !important;
}

.list-group-item {
  border: none !important;
}

.list-group-item:hover {
  background-color: #f3f6fa !important;
  color: #000000;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.tglbtn1 {
  background-color: #464c53 !important;
  border: none !important;
  height: 40px !important;
  width: 40px !important;
  margin: 6px;
}

.tglbtn1:hover {
  background-color: rgb(114, 114, 114) !important;
}

.ql-editor {
  padding: 0px !important;
  font-size: 18px !important;
}

.ql-container {
  height: 75% !important;
}

.ql-editor p {
  font-size: medium !important;
}

/* ScrollToTopButton.css */

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-button {
  background-color: #007bff;
  color: white;
  font-size: 20px;
  padding: 7px 14px 7px 14px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.4s;
  scroll-behavior: smooth !important;
}

.scroll-button:hover {
  background-color: #012F76;
}

.scroll-button svg {
  width: 20px;
}